import { useTranslations } from "next-intl";
import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../animations/empty-ic.json";

const EMPTY_WIDTH = 250;

interface EmptyAnimProps {
  createNewText: string;
  onCreateNew: () => void;
}

export default function EmptyAnim({
  createNewText,
  onCreateNew,
}: EmptyAnimProps) {
  const translate = useTranslations();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Lottie options={defaultOptions} width={EMPTY_WIDTH} />
      <div className="mt-4 text-sm text-gray-500">{translate("emptyDesc")}</div>
      {createNewText && (
        <div onClick={onCreateNew}>
          <div
            className={`mt-1 text-sm font-bold underline cursor-pointer hover:text-main-blue`}
          >
            {createNewText}
          </div>
        </div>
      )}
    </div>
  );
}
