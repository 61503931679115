import { ApolloError } from "@apollo/client";
import ErrorMessage from "../errorMessage/ErrorMessage";
import LoadingIcon from "../loadingIcon/LoadingIcon";
import FullScreenLoading from "./FullScreenLoading";

interface HandleQueryProps {
  loading: boolean;
  error?: ApolloError;
  children: JSX.Element;
  isFullScreenLoading?: boolean;
}

export default function HandleQuery({
  loading,
  error,
  children,
  isFullScreenLoading = false,
}: HandleQueryProps) {
  if (error) {
    return (
      <div className="w-full h-32 mt-16 flex items-center justify-center">
        <ErrorMessage message={error.message} />
      </div>
    );
  }

  if (loading) {
    if (isFullScreenLoading) {
      return <FullScreenLoading />;
    }
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return <>{children}</>;
}
