import React from "react";
import LoadingIcon from "../loadingIcon/LoadingIcon";

export default function FullScreenLoading() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <LoadingIcon />
    </div>
  );
}
