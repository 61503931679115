import React from "react";
import * as animationData from "../../animations/error-ic.json";
import Lottie from "react-lottie";

const ANIM_SIZE = 180;

interface ErrorMessageProps {
  message: string;
}

export default function ErrorMessage({ message }: ErrorMessageProps) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Lottie options={defaultOptions} width={ANIM_SIZE} />
      <span className="text-red-500 text-xl font-semibold">{message}</span>
    </div>
  );
}
