import React from "react";
import {
  MAX_ADDITIONAL_TOTAL_PLAYERS,
  MAX_AMOUNT_SHOWED_PLAYERS,
} from "../../constants/common";
import { Player } from "../../resolvers-types";
import Image from "next/image";

interface JoinedPlayersListProps {
  playersInRoom: Player[];
}

const PLAYER_AVA_SIZE = 32;

export default function JoinedPlayersList({
  playersInRoom,
}: JoinedPlayersListProps) {
  const totalPlayersAmount = playersInRoom.length;
  const playersShowed =
    totalPlayersAmount > MAX_AMOUNT_SHOWED_PLAYERS
      ? playersInRoom.slice(0, MAX_AMOUNT_SHOWED_PLAYERS)
      : playersInRoom;
  const remainingPlayerAmount = totalPlayersAmount - playersShowed.length;
  const showedRemainingPlayerMount =
    remainingPlayerAmount > MAX_ADDITIONAL_TOTAL_PLAYERS
      ? MAX_ADDITIONAL_TOTAL_PLAYERS
      : remainingPlayerAmount;
  return (
    <div className="flex flex-row items-center">
      {playersShowed.map((player) => {
        return (
          <div
            className="mr-3 items-center flex rounded-full border-2 border-dark-blue dark:border-white bg-dark-blue dark:bg-white"
            key={player.id}
          >
            <Image
              className="rounded-full"
              alt={`${player?.user?.displayName} avatar`}
              src={player.user.avatarUrl}
              width={PLAYER_AVA_SIZE}
              height={PLAYER_AVA_SIZE}
            />
          </div>
        );
      })}
      {!!showedRemainingPlayerMount && (
        <div className="rounded-full bg-yellow-600 border-2 border-dark-blue dark:border-white">
          <div
            style={{ width: PLAYER_AVA_SIZE, height: PLAYER_AVA_SIZE }}
            className="rounded-full flex items-center justify-center"
          >
            <div className="text-xs font-bold text-white flex items-center justify-center">
              <div style={{ marginRight: 1 }}>+</div>
              {showedRemainingPlayerMount}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
