import { NetworkStatus } from "@apollo/client";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import React from "react";
import ReactTooltip from "react-tooltip";
import { isCreateEstimateRoomModalVisibleVar } from "../../../graphql/cache";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { Player, useGetJoinedRoomsQuery } from "../../../resolvers-types";
import HandleQuery from "../../common/HandleQuery";
import EmptyAnim from "../../emptyAnim/EmptyAnim";
import JoinedPlayersList from "../../joinedPlayersList/JoinedPlayersList";

interface JoinedEstimateRoomsProps {
  isFullScreen?: boolean;
}

const SYNCE_ROOMS_LIST_TOOLTIP_ID = "sync-rooms";

export default function JoinedEstimateRooms({
  isFullScreen,
}: JoinedEstimateRoomsProps) {
  const translate = useTranslations();
  const {currentUser} = useCurrentUser();
  const { data, error, networkStatus, refetch } = useGetJoinedRoomsQuery({
    skip: !currentUser,
    notifyOnNetworkStatusChange: true,
  });
  const joinedRooms = data?.joinedRooms || [];
  const router = useRouter();

  const handleClickRoom = (roomId: string) => () => {
    router.push(`/estimate/${roomId}`);
  };

  const isRoomsEmpty = joinedRooms.length === 0;
  const isRefetching = networkStatus === NetworkStatus.refetch;
  const isLoading = networkStatus === NetworkStatus.loading;

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        <div className="font-semibold text-2xl select-none pl-5">
          {translate("joinedRooms")}
        </div>
        {currentUser && (
          <div
            className="ml-2 w-6 h-6 rounded-full bg-main-blue flex items-center justify-center hover:bg-blue-800 cursor-pointer"
            onClick={handleRefetch}
            data-for={SYNCE_ROOMS_LIST_TOOLTIP_ID}
            data-tip={translate("syncRooms")}
          >
            <FontAwesomeIcon
              icon={faSync}
              className="text-white"
              size="xs"
              spin={isRefetching}
            />
            <ReactTooltip id={SYNCE_ROOMS_LIST_TOOLTIP_ID} />
          </div>
        )}
      </div>
      <HandleQuery loading={isLoading} error={error}>
        {isRoomsEmpty ? (
          <EmptyAnim
            createNewText={translate("createEstimateRoom") as string}
            onCreateNew={() => isCreateEstimateRoomModalVisibleVar(true)}
          />
        ) : (
          <div className="mt-8 w-full">
            <div className="w-full flex flex-row">
              <div className="text-left pb-3 text-sm text-gray-700 dark:text-gray-400 font-semibold pl-5 w-1/2">
                {translate("name")}
              </div>
              <div className="text-left pb-3 text-sm text-gray-700 dark:text-gray-400 font-semibold pl-5 w-1/2">
                {translate("players")}
              </div>
            </div>
            <div
              className={`${
                isFullScreen ? "h-auto" : "max-h-300"
              } w-full overflow-auto`}
            >
              {joinedRooms.map((joinedRoom) => {
                const { players, name, uniqId } = joinedRoom;
                return (
                  <div
                    key={joinedRoom.id}
                    className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 flex flex-row rounded-lg items-center"
                    onClick={handleClickRoom(uniqId)}
                  >
                    <div className="text-left py-4 pl-5 rounded-tl-lg rounded-bl-lg w-1/2">
                      {name}
                    </div>
                    <div className="text-left py-4 pl-5 rounded-tr-lg rounded-br-lg w-1/2">
                      <JoinedPlayersList playersInRoom={players as Player[]} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </HandleQuery>
    </div>
  );
}
